.dropdown-menu {
    height: 130px;
    overflow-y: scroll;
    margin-left: 10px;
  } 
  .option{ 
    color: black;
    }
  .chips {
    background-color:darkgray;
  } 
  .searchBox { 
    border: none;
    /* font-size: 10px; */
    min-height: 35px;
 
    color: black;
    }
    .inputField { 
        margin: 5px;
        color: black;
    }
  
    .coloredTab {
      color: red;
    }

    .container {
      width: 80%;
      margin: 50px auto;
    }
    
    .button {
      background: #2196f3;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      color: #fff;
      font-weight: bold;
    }
    
    .button:hover {
      background: #0b7dda;
    }

    /* Style the modal */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
}

.modal-text {
  margin-left: 14px;
  font-size: 24px;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #ddd;
  border-top: 8px solid #2196f3;
  border-bottom: 8px solid #2196f3;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  