.multiselectContainer { 
        /* // To change css for multiselect (Width,height,etc..) */
        color: white;
        background:white;
        border-width: 0pt;
      
       
      }
      .searchBox { 
        /* // To change search box element look */
      /* border: none; */
      /* height: 40pt; */
      font-size: 15px;
      color: white;
 
        }
      .inputField { 
        /* // To change input field position or margin */
          margin: 0px;
          color: white;
          height: 10px;
      }
      .chips { 
        /* // To change css chips(Selected options) */
      background: red;
      color: white;
     
      }
      .optionContainer { 
        /* // To change css for option container  */
      border: 2px solid;
      color: white;
      

      }
      .option { 
        /* // To change css for dropdown options */
      color: rgb(0, 0, 0);
      }
      .groupHeading { 
        /* // To chanage group heading style */
      
      }
    
  