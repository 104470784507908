.App {
  width: 100%;
  height: 100vh;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}
  
table {
    font-family: arial, sans-serif;
    border-collapse:separate;
  
  }
  
td, th {
    border: 1px solid #000000;
    text-align: left;
    padding: 10px;
    color: black;
  }
  
tr:nth-child(even) {
    /* background-color: #dddddd; */
    background-color: #dddddd;

  }


  #employee {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #employee td, #employee th {
    width: 10pt;
    border: 1px solid #ddd;
    padding: 8px;
    font-size:10pt;
  }
  
  #employee tr:nth-child(even){
    background-color: #f2f2f2;}
  
  #employee tr:hover {
    background-color: #ddd;}
  
  #employee th {
    width: 10pt;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #155F7F;
    color: white;
    font-size:10pt;
  }